let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-saas-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-saas-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://dlp56kb6cvgmrm2hbhmujaiac4.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://2lhjckdeg8.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://v4xfxmrgmj.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.saas.forwoodsafety.com",
        WEBSOCKET: "wss://k9ttmpq1n8.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_3wLpx8Fn3",
        IDENTITY_POOL_ID: "us-west-2:3cd1cd25-d366-4800-aba2-88193dd0b9ab",
        USERPOOL_HOSTED_DOMAIN: "forwood-saas-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.saas.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.saas.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::851088287280:role/prod-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure,OzMinerals,Bellevue,AGA,PilbaraMinerals,Covalent,newgold,kenmare'
    },
    reactApp: {
        VERSION: "1.35.4",
        HOSTNAME: "id.saas.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".saas.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.saas.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
